<template>
  <div class="home" id="home" name="home">
    <!-- 轮播图 -->
    <!-- <div class="block">
      <el-carousel>
        <el-carousel-item v-for="item in carousel" :key="item.carousel_id">
          <img style="width: 100%; height: 100%;" :src="item.imgUrl" :alt="item.title" />
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <!-- 轮播图END -->
    <div class="main-box">
      <div class="main">
        <!-- 手机商品展示区域 -->
        <div class="phone" v-for="(item,index) in CommodityList" :key="index">
          <div class="box-hd">
            <div class="title">{{item.labelName}}</div>
          </div>
          <div class="box-bd">
            <div class="promo-list">
              <img v-if="item.apiCommodityListPageVos.length >= 5" :src="item.highBannerUrl" />
              <img v-else :src="item.lowBannerUrl" />
            </div>
            <div class="list">
              <MyList :list="item.apiCommodityListPageVos"  :pagename="'home'" :selectedSort="item.selectedSort" :isMore="true"></MyList>
            </div>
          </div>
        </div>
        <!-- 手机商品展示区域END -->

      
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      carousel: "", // 轮播图数据
      phoneList: "", // 手机商品列表
      miTvList: "", // 小米电视商品列表
      applianceList: "", // 家电商品列表
      applianceHotList: "", //热门家电商品列表
      accessoryList: "", //配件商品列表
      accessoryHotList: "", //热门配件商品列表
      protectingShellList: "", // 保护套商品列表
      chargerList: "", //充电器商品列表
      applianceActive: 1, // 家电当前选中的商品分类
      accessoryActive: 1, // 配件当前选中的商品分类
      CommodityList:[]//商品列表
    };
  },
  watch: {
    
  },
  created() {
    // 获取轮播图数据
    this.$axios
      .get("/zuwu-api/commodity/recommendList?direction=1&labels=1,2,3,4,5,6,7,8,9")
      .then(res => {
        // console.log(111,res.data.data.apiCommodityLabelListVo.commodityLabelList.slice(0, 4))
        // this.carousel = res.data.data.apiBannerListVO.officialWebLeftBanner
        this.CommodityList = res.data.data.apiCommodityLabelListVo.commodityLabelList.slice(0, 4);
      })
      .catch(err => {
        return Promise.reject(err);
      });
   
  },
  methods: {
    // 获取家电模块子组件传过来的数据
    getChildMsg(val) {
      this.applianceActive = val;
    },
    // 获取配件模块子组件传过来的数据
    getChildMsg2(val) {
      this.accessoryActive = val;
    }
  }
};
</script>
<style scoped>
@import "../assets/css/index.css";
</style>